import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, OutlinedInput, Typography } from '@mui/material';
import { AssetDropdownSelect } from 'components';
import { useWeb3React } from '@web3-react/core';
import { getDepositContract } from 'utils/depositContractHelpers';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { DEPOSIT_CONTRACT } from 'data/depositContract';
import { toast } from 'react-toastify';
import { getUserDeposit } from 'apis';
import { hasOneHourPassed } from 'utils/lockDuration';
import moment from 'moment';

const inputStyles = {
	'&, &:hover': {
		outline: '1px solid #235EE1',
	},
	color: 'white',
	fontWeight: 500,
	'& .MuiOutlinedInput-root': {
		fontWeight: 500,
	},
	'& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
		display: 'none',
	},
};

type Response = {
	address: string;
	amount: string;
	contract: string;
	createdAt: string;
	id: string;
	updatedAt: string;
};

const WithdrawPanel: React.ComponentType<{ onClose?(): void }> = ({
	onClose,
}) => {
	const [lockedTime, setLockedTime] = useState<string | undefined>(undefined);
	const [loading, setLoading] = useState(true);
	const [balance, setBalance] = useState('0');
	const { account, library } = useWeb3React();
	const appState = useSelector((state: RootState) => state.app);
	const contractAddress =
		DEPOSIT_CONTRACT[appState.selectedNetwork].contractAddress;
	const SelectedContract = DEPOSIT_CONTRACT[
		appState.selectedNetwork
	].token.find((d) => d.name === 'USDC');

	const [withdrawalAmount, setWithdrawalAmount] = useState('');

	const fetchBalance = useCallback(async () => {
		try {
			const contract = getDepositContract(
				contractAddress,
				library,
				account as string,
			);

			// fetch balance
			const contractBalance = await contract.balanceOf(account);
			const bal = await contract.convertToAssets(contractBalance);

			setBalance(formatUnits(bal, SelectedContract?.decimalValue));
		} catch (e) {
			//
		} finally {
			setLoading(false);
		}
	}, [SelectedContract?.decimalValue, account, contractAddress, library]);

	const getUserData = useCallback(async () => {
		try {
			const res = await getUserDeposit({
				contract: contractAddress,
				address: account || '',
			});
			if (res.lastDeposit.length > 0) {
				setLockedTime(res.lastDeposit[0].createdAt);
			}
		} catch (e) {
			//
		}
	}, [account, contractAddress]);

	const fillMaxAmount = useCallback(() => {
		setWithdrawalAmount(balance.toString());
	}, [balance]);

	useEffect(() => {
		getUserData();
		fetchBalance();
	}, [fetchBalance, getUserData]);

	const endAdornment = useMemo(() => {
		return (
			<Box>
				<Button onClick={fillMaxAmount}>
					<Typography fontSize={12} fontWeight={500}>
						MAX
					</Typography>
				</Button>
			</Box>
		);
	}, [fillMaxAmount]);

	useEffect(() => {
		setWithdrawalAmount('');
	}, []);

	const withdraw = async () => {
		try {
			setLoading(true);

			const res = await getUserDeposit({
				contract: contractAddress,
				address: account || '',
			});
			if (res.lastDeposit.length > 0) {
				setLockedTime(res.lastDeposit[0].createdAt);
				if (!hasOneHourPassed(res.lastDeposit[0].createdAt)) {
					const contract = getDepositContract(
						contractAddress,
						library,
						account as string,
					);

					await contract.withdraw(
						parseUnits(
							withdrawalAmount,
							SelectedContract?.decimalValue,
						),
						account,
						account,
					);
					toast('Successfully withdrawn from vault contract', {
						type: 'success',
					});
					onClose?.();
					return;
				}
			}
			toast('Sorry your amount is locked for 1h', {
				type: 'error',
			});
		} catch (e) {
			toast('Something went wrong', { type: 'error' });
		} finally {
			setLoading(false);
			fetchBalance();
		}
	};

	const buttonLabel = useMemo(() => {
		if (!account) return 'Connect Wallet';

		if (loading) return 'Please wait...';

		if (lockedTime && hasOneHourPassed(lockedTime || '')) {
			return (
				hasOneHourPassed(lockedTime || '') &&
				`Unlock at ${moment(lockedTime)
					.add(1, 'hours')
					.format('DD-MMM, hh:mm A')}`
			);
		}

		return 'Withdraw';
	}, [account, loading, lockedTime]);

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
			>
				<Typography color="#E6E6E9">Select asset</Typography>
				<Box display="flex" alignItems="center">
					<Typography color="#E6E6E9" fontWeight={500} fontSize={12}>
						{balance} {'USDC'}
					</Typography>
					<Box width={4} />
					<img src="/icons/wallet.png" />
				</Box>
			</Box>
			<Box height={8} />
			<AssetDropdownSelect />

			<Box height={16} />

			<Typography color="#E6E6E9">Amount</Typography>
			<Box height={8} />

			<OutlinedInput
				disabled={loading}
				placeholder="0.00"
				value={withdrawalAmount}
				onChange={(event) => {
					setWithdrawalAmount(event.target.value);
				}}
				type="number"
				fullWidth
				margin="dense"
				sx={inputStyles}
				endAdornment={endAdornment}
			/>

			<Box height={28} />

			<Button
				sx={{
					height: 52,
				}}
				variant="contained"
				fullWidth
				color="primary"
				onClick={() => {
					withdraw();
				}}
				disabled={
					parseFloat(balance) < parseFloat(withdrawalAmount) ||
					loading ||
					withdrawalAmount === '' ||
					hasOneHourPassed(lockedTime || '')
				}
			>
				<Typography
					variant={'button'}
					fontWeight="bold"
					py="6px"
					style={{ lineHeight: '120%' }}
				>
					{buttonLabel}
				</Typography>
			</Button>
		</Box>
	);
};

export { WithdrawPanel };
