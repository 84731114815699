import { useEffect } from 'react';
import { appActions } from 'store/app';
import { NETWORK } from 'data/depositContract';
import { useAppDispatch } from 'hooks/hooks';
import { ChainNames } from 'config';
import { useAuth } from 'hooks/useAuth';
import { useLocation } from 'react-router-dom';

export const useNetwork = () => {
	const { logout } = useAuth();
	const dispatch = useAppDispatch();
	const query = new URLSearchParams(useLocation().search);
	const network = (query.get('network') || '').toLowerCase();
	const networkId = (
		(localStorage.getItem('networkId') as ChainNames) || ''
	).toLowerCase();
	const selectedNetwork = (
		NETWORK[network as keyof typeof NETWORK] || ''
	).toLowerCase();

	useEffect(() => {
		dispatch(
			appActions.setSelectedChain(
				NETWORK[network as keyof typeof NETWORK] || 'Base',
			),
		);

		if (
			networkId !== '' &&
			selectedNetwork !== '' &&
			networkId !== selectedNetwork
		) {
			logout();
		}
	}, [dispatch, logout, network, networkId, selectedNetwork]);
};
