import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { ChainNames } from 'config';

export type AppState = {
	selectedNetwork: ChainNames;
};

const initialState: AppState = {
	selectedNetwork: 'Move-EVM',
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setSelectedChain: (
			state: Draft<AppState>,
			action: PayloadAction<ChainNames>,
		) => {
			state.selectedNetwork = action.payload;
		},
	},
});

export const appActions = appSlice.actions;
const appReducer = appSlice.reducer;

export default appReducer;
