import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { PoolItem } from './pool-item';
import { FlexCol } from 'components/Flex';
import { Gutter } from 'components/Gutter';
import { Link, useLocation } from 'react-router-dom';
import { SMMPool, sMMPools } from 'data';

import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNetwork } from 'hooks/useNetwork';

const Container = styled.div`
	display: flex;
	width: 100%;
	height: 80vh;

	padding: 10px;
	justify-content: center;
	align-items: center;

	@media (max-width: 600px) {
		height: auto;
		margin: 46px 0 0 0;
	}
`;

const CarouselWrapper = styled('div')`
	position: relative;
	display: block;
	max-width: 1120px;
	width: 100%;
	height: auto;
`;

const Col = styled(FlexCol)`
	max-width: 100%;
	align-items: center;
`;

const StyledLink = styled(Link)`
	display: block;
	text-decoration: none;
	width: min-content;
	height: 100%;
`;

const PoolSelection: React.ComponentType = () => {
	const query = new URLSearchParams(useLocation().search);
	const network = (query.get('network') || '').toLowerCase();
	useNetwork();

	const RenderPool = useCallback(({ ...pool }: SMMPool) => {
		return (
			<>
				{!pool.disabled ? (
					<StyledLink
						to={
							network
								? `main?strategy=${pool.id}&network=${network}`
								: `main?strategy=${pool.id}`
						}
					>
						<PoolItem {...pool} />
					</StyledLink>
				) : (
					<PoolItem {...pool} />
				)}
			</>
		);
	}, []);

	return (
		<Container>
			<Col>
				<Typography
					variant={'h3'}
					color={'#fff'}
					style={{ alignSelf: 'flex-start' }}
				>
					Our vaults
				</Typography>
				<Box height={32} />
				<CarouselWrapper>
					<Swiper
						slidesPerView={'auto'}
						spaceBetween={24}
						pagination={{ type: 'progressbar', clickable: true }}
						className="mySwiper"
					>
						{sMMPools.map((pool) => (
							<SwiperSlide
								key={pool.id}
								style={{
									width: 'min-content',
									height: 'auto',
									paddingBottom: 2,
								}}
							>
								<RenderPool {...pool} />
							</SwiperSlide>
						))}
					</Swiper>
				</CarouselWrapper>
				<Gutter />
			</Col>
		</Container>
	);
};

export { PoolSelection };
