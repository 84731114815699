export const DEPOSIT_CONTRACT = {
	Local: {
		token: [
			{
				id: 0,
				name: 'USDC',
				icon: '',
				address: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913',
				decimalValue: 6,
				maxAllowed: 5,
			},
		],
		contractAddress: '0xA142E329949dcCcD426BdeAa78CBA103D8424Abc',
	},
	Base: {
		token: [
			{
				id: 0,
				name: 'USDC',
				icon: '',
				address: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913',
				decimalValue: 6,
				maxAllowed: 5,
			},
		],
		contractAddress: '0xA142E329949dcCcD426BdeAa78CBA103D8424Abc',
	},
	Avalanche: {
		token: [
			{
				id: 0,
				name: 'USDC',
				icon: '',
				address: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
				decimalValue: 6,
				maxAllowed: 5,
			},
		],
		contractAddress: '0x6F2dEa7b2A4965196511645104FDe0baBD227783',
	},
	'Move-EVM': {
		token: [
			{
				id: 0,
				name: 'USDC',
				icon: '',
				address: '0x05C3aCf16d546cd3c5D8239A9B03e959890DC32C',
				decimalValue: 18,
				maxAllowed: 5,
			},
		],
		contractAddress: '0x69EcB12aA34edB9A119eac3d48f6a8ADf33B26a5',
	},
};

export enum NETWORK {
	'movement_parthenon' = 'Move-EVM',
	'base' = 'Base',
	'avalanche' = 'Avalanche',
	'local' = 'Base',
}
