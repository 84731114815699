import React, { useCallback } from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { WalletInfo } from 'data';
import { useAppDispatch, useAuth } from 'hooks';
import { walletModalActions } from 'store/wallet-modal';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const StyledBox = styled(Box)<{ disabled: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px;
	margin-right: 20px;
	width: 100%;
	margin-bottom: 8px;
	cursor: ${(props) => (!props.disabled ? 'pointer' : 'initial')};

	filter: ${(props) =>
		props.disabled ? 'blur(1px) grayscale(100%)' : 'none'};
	border-radius: 4px;
	border: ${(props) =>
		!props.disabled ? '1px solid #d6d6d6' : '0px solid #d6d6d6'};
	background: #2a2a2a;

	&:hover {
		background-color: ${(props) => (props.disabled ? 'none' : '#4d4d60')};
	}
`;

type Props = WalletInfo;

const WalletTile: React.ComponentType<Props> = ({
	name,
	disabled,
	image,
	connector,
}) => {
	const { login } = useAuth();
	const dispatch = useAppDispatch();
	const appState = useSelector((state: RootState) => state.app);

	const onWalletSelected = useCallback(() => {
		if (disabled) return;

		const hostname = window.location.hostname;
		if (hostname.includes('dashboard2')) {
			toast(
				'Wallet connection not allowed, since we are not live, please join discord to get an invite for our beta testing',
				{
					type: 'info',
				},
			);
			return;
		}

		login(connector, appState.selectedNetwork || 'Move-EVM');
		dispatch(walletModalActions.closeWalletModal());
	}, [appState.selectedNetwork, connector, disabled, dispatch, login]);

	return (
		<StyledBox disabled={disabled} onClick={onWalletSelected}>
			<img src={image} height={24} />
			<Box
				sx={{
					width: 10,
				}}
			/>
			<Typography
				variant={'body1'}
				textAlign="center"
				color={'#D6D6D6'}
				fontWeight={500}
			>
				{disabled ? 'Coming Soon' : name}
			</Typography>
		</StyledBox>
	);
};

export { WalletTile };
